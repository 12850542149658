import React, { useContext } from 'react';
import { Box } from '@latitude/box';
import { AccordionSidebar } from '@latitude/accordion';
import { Heading4, Heading5 } from '@latitude/heading';
import { ImportantInformation } from '@latitude/important-information';
import { List, ListItem } from '@latitude/list';
import { Metadata } from '@latitude/metadata';
import { FeaturesSlider } from '@latitude/features-slider';
import { Text } from '@latitude/text';
import ListNumbered from '@latitude/list-numbered/ListNumbered';
import FramedContainer from '@latitude/framed-container/FramedContainer';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  HEADING,
  MARGIN,
  PADDING,
  FONT_SIZE
} from '@latitude/core/utils/constants';
import Layout from '@/components/layout';
import { SITE_URL } from '@/utils/constants';
import Hero from './_hero';
import faqs from './_faqs';
import StickyNavigation from './_sticky-navigation';
import imgHowToUse from './images/nz_digital_wallet-apple-pay-phone.png';
import { PageContext } from '../../context/PageContext';
import { getAccordionSidebarData } from '@/utils/helpers';
import HeroBanner from '@/components/lab-components/HeroBanner';

const ApplePay = ({ location }) => {
  const featureSliderItems = [
      {
        icon: 'icon-padlock-confetti',
        title: "It's simple and secure",
        text:
          'With Apple Pay, you can use your Gem Visa card to buy the everyday little stuff, in just a touch. And when you use Apple Pay, your card details are never shared or saved.'
      },
      {
        icon: 'icon-easy',
        title: 'Setup is simple',
        text: 'Add your Gem Visa card to Apple Pay in three simple steps.'
      },
      {
        icon: 'icon-purchase-protection-confetti',
        title: 'How to use',
        text:
          'To pay in stores, hold iPhone near the reader with your finger on Touch ID. On Apple Watch, just double-click the side button and hold Apple Watch up to the reader. To pay in apps and on the web with iPhone and iPad, select Apple Pay as the payment method and place your finger on Touch ID. On Mac in Safari, choose Apple Pay then complete the purchase on your iPhone or Apple Watch.*'
      }
    ]

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const featureSliderData = state?.featureSliderData || [{
    heading: "Why Apple Pay?",
    featureCards: featureSliderItems
  }];
  /** - END - */

  return (
    <Layout location={location} css={`border: red`}>
      <main className="navigation-spacer">
      <Metadata
        title="Apple Pay &amp; Wallet Setup | Mobile Tap &amp; Pay | Gem by Latitude"
        description="Apple Pay is now available to Gem Visa customers. See how to setup Apple Pay on your Apple devices and make your payments more simple and secure."
        canonical={`${SITE_URL}${location.pathname}`}
      />
      {state?.heroBannerData?.[0] ? (
        <HeroBanner {...state?.heroBannerData[0]} />
      ) : (
        <Hero data-sticky-navigation-offset />
      )}
      <StickyNavigation />
      {featureSliderData[0] && (
        <Box backgroundColor={COLOR.WHITE}>
          <FeaturesSlider
            heading={featureSliderData[0].heading}
            subheading={featureSliderData[0].description}
            data={featureSliderData[0].featureCards}
          />
        </Box>
      )}
      <Box.Section backgroundColor={COLOR.GREY6}>
        <Heading4
          align={ALIGN.CENTER}
          color={COLOR.BLACK}
          marginBottom={MARGIN.M40}
          css={`
            @media (min-width: ${BREAKPOINT.LG}) {
              max-width: 730px;
              margin-left: auto;
              margin-right: auto;
            }
          `}
        >
          If you already have a card, adding it to Apple Pay is simple. Here’s
          how to get started.
        </Heading4>
        <Box
          css={`
            display: grid;
            grid-gap: ${MARGIN.M24} ${MARGIN.M40};
            h5 {
              margin-bottom: ${MARGIN.M16};
              color: ${COLOR.BLACK};
            }
            @media (max-width: ${BREAKPOINT.SM}) {
              h5 {
                font-size: ${HEADING.h6.FONT_SIZE.NORMAL};
                line-height: ${HEADING.h6.LINE_HEIGHT.NORMAL};
              }
            }
            @media (min-width: ${BREAKPOINT.LG}) {
              grid-template-columns: repeat(3, 1fr);
              grid-template-rows: 2;
            }
          `}
        >
          <Box>
            <Heading5>iPhone</Heading5>
            <List>
              <ListItem>Open the Wallet app.</ListItem>
              <ListItem>Tap the “+” sign in the upper-right corner.</ListItem>
              <ListItem>
                Enter your card’s security code to add your credit or debit card
                affiliated with your iTunes account. Or, select “Add a different
                card,” and use your iPhone camera to scan the card information.*
              </ListItem>
            </List>
          </Box>
          <Box
            css={`
              text-align: center;
              @media (min-width: ${BREAKPOINT.LG}) {
                grid-column: 1;
                grid-row: 1 / 3;
              }
            `}
          >
            <img
              css={`
                max-width: 240px;
              `}
              src={imgHowToUse}
              alt="Phone - Apple Pay"
            />
          </Box>
          <Box
            css={`
              @media (min-width: ${BREAKPOINT.LG}) {
                grid-area: 2 / 2;
              }
            `}
          >
            <Heading5>Apple Watch</Heading5>
            <List>
              <ListItem>Open the Apple Watch app on your iPhone.</ListItem>
              <ListItem>
                Tap “Wallet & Apple Pay” and select “Add Credit or Debit Card.”
              </ListItem>
              <ListItem>
                Enter your card’s security code to add your credit or debit card
                affiliated with your iTunes account. Or, select “Add a different
                card,” and use your iPhone camera to scan the card information.*
              </ListItem>
            </List>
          </Box>

          <Box>
            <Heading5>iPad</Heading5>
            <List>
              <ListItem>Go to Settings.</ListItem>
              <ListItem>
                Tap “Wallet & Apple Pay,” and select “Add Credit or Debit Card.”
              </ListItem>
              <ListItem>
                Enter your card’s security code to add your credit or debit card
                affiliated with your iTunes account. Or, select “Add a different
                card,” and use your iPhone camera to scan the card information.*
              </ListItem>
            </List>
          </Box>
          <Box>
            <Heading5>MacBook Pro with Touch ID</Heading5>
            <List>
              <ListItem>Go to System Preferences.</ListItem>
              <ListItem>
                Select “Wallet & Apple Pay” and select “Add Card.”
              </ListItem>
              <ListItem>
                Enter your card’s security code to add your credit or debit card
                affiliated with your iTunes account.
              </ListItem>
              <ListItem>
                Or, select “Add a different card,” and use your iPhone camera to
                scan the card information.*
              </ListItem>
            </List>
          </Box>
          <Box
            css={`
              @media (min-width: ${BREAKPOINT.LG}) {
                grid-column: 2;
              }
            `}
          >
            <Heading5>Paying in stores</Heading5>
            <List>
              <ListItem>
                Apple Pay is the easiest way to pay in stores with your iPhone
                and Apple Watch. You can quickly check out using the devices you
                carry every day.
              </ListItem>
            </List>
          </Box>
          <Box>
            <Heading5>Paying in apps and on the web</Heading5>
            <List>
              <ListItem>
                Use Apple Pay to make purchases in all kinds of apps on your
                iPhone and iPad. Paying is as simple as selecting Apple Pay at
                checkout and completing the payment using Face ID or Touch ID.
                Apple Pay is also the easier, safer way to pay online in Safari
                on your iPhone, iPad, and Mac, as you no longer have to create
                an account or fill out lengthy forms. Simply pay and go. You can
                also pay on your MacBook Pro using Touch ID.*
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box.Section>
      <Box.Section
        isResponsive
        isBodyCopy
        css={`
          padding: 8px 0;
          @media (min-width: ${BREAKPOINT.LG}) {
            padding: 40px 0;
          }
        `}
        backgroundColor={COLOR.BLUE_BABY}
      >
        <Box padding="0 15px">
          <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
            Just got approved? You don&apos;t have to wait for your card to
            start spending.
          </Heading4>
          <Box marginBottom={MARGIN.M32}>
            <Text align={ALIGN.CENTER}>
              If you have a Gem Visa card here&apos;s how you can start using
              your card instantly via Apple Pay:
            </Text>
          </Box>
          <FramedContainer
            theme={{
              frameColor: COLOR.BLUE_LIGHT_BRIGHT,
              contentBorderColor: 'none'
            }}
          >
            <Box padding={PADDING.P24}>
              <ListNumbered
                data={[
                  'First, download the <a href="https://apps.apple.com/nz/app/latitude-app/id1373059866" style="display: contents; text-decoration: underline;">Latitude App</a> and create your Latitude ID.',
                  'Link your Gem Visa card using your account number (you&apos;ll find this in the email we sent to you when you were approved). You&apos;ll receive a verification SMS with a four-digit code to insert.',
                  'Next, go to your Apple Pay wallet on your phone and insert your name and date of birth. You may receive a verification SMS at this stage.',
                  'Start using your card instantly through Apple Pay.'
                ]}
                viewStyle="compact"
                counterBgColor={COLOR.BLUE_DEEP}
                counterVerticalAlign
                separator="light"
              />
              <div>
                <hr className="mt-3 mb-4 light-border" />
                <Text fontSize={FONT_SIZE.SMALL}>
                  It&apos;s important to be aware that you might be limited to
                  transactions under $80 until your card arrives in the post,
                  you activate it and set a PIN.
                </Text>
              </div>
            </Box>
          </FramedContainer>
        </Box>
      </Box.Section>
      <Box.Section>
        <div data-contentful={state?.faqData?.[0]?.contentfulID}>
          <AccordionSidebar
            data={
              getAccordionSidebarData(state?.faqData?.[0]?.data) ||
              faqs.applePay
            }
          />
        </div>
      </Box.Section>
      <ImportantInformation
        data={{
          content: {
            importantInformationSectionOne: [
              '&copy;2016 Apple Inc. All rights reserved. Apple, the Apple logo, Apple Pay, iPad, iPad Air, iPhone, MacBook Air, Touch ID, and watchOS are trademarks of Apple Inc., registered in the U.S. and other countries. Apple Store and App Store are service marks of Apple Inc., registered in the U.S. and other countries. IOS is a trademark or registered trademark of Cisco in the U.S. and other countries and is used under license. Other product and company names mentioned herein may be trademarks of their respective companies.'
            ],
            importantInformationSectionTwo: [
              '<sup>*</sup> Apple Pay works with iPhone 6 and later in stores, apps, and websites in Safari; with Apple Watch in stores and apps (requires iPhone 6 or later); with iPad Pro, iPad (5th generation), iPad Air 2, and iPad mini 3 and later in apps and websites in Safari; and with Mac (2012 or later) in Safari with an Apple Pay-enabled iPhone or Apple Watch. For a list of compatible Apple Pay devices, see <a href="https://support.apple.com/km207105">https://support.apple.com/km207105</a>.'
            ]
          }
        }}
        sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
        sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
        {...state?.importantInfoData?.[0]}
      />
      </main>
    </Layout>
  );
}

export default ApplePay;
